"use strict";

var _debug = _interopRequireDefault(require("debug"));

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _resource = _interopRequireDefault(require("./resource"));

var _list = _interopRequireDefault(require("./list"));

var _strategies = _interopRequireDefault(require("./strategies"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = (0, _debug.default)('feathers-reactive');

function FeathersRx() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var listStrategies = (0, _strategies.default)();

  if (!options.idField) {
    throw new Error("feathers-reactive: setting options.idField is mandatory");
  }

  options = Object.assign({
    dataField: 'data',
    sorter: _utils.makeSorter,
    matcher: _utils.siftMatcher,
    // Whether to requery service when a change is detected
    listStrategy: 'smart',
    listStrategies: listStrategies
  }, options);

  var mixin = function mixin(service) {
    var app = this;
    var events = {
      // fromEvent's result selector (3rd arg) is deprecated
      // we need it here because service events have an inconsistent number of arguments (i.e. sometimes 1, sometimes >1)
      // related: https://github.com/ReactiveX/rxjs/issues/3751
      created: (0, _rxjs.fromEvent)(service, 'created', function () {
        return arguments.length <= 0 ? undefined : arguments[0];
      }).pipe((0, _operators.share)()),
      updated: (0, _rxjs.fromEvent)(service, 'updated', function () {
        return arguments.length <= 0 ? undefined : arguments[0];
      }).pipe((0, _operators.share)()),
      patched: (0, _rxjs.fromEvent)(service, 'patched', function () {
        return arguments.length <= 0 ? undefined : arguments[0];
      }).pipe((0, _operators.share)()),
      removed: (0, _rxjs.fromEvent)(service, 'removed', function () {
        return arguments.length <= 0 ? undefined : arguments[0];
      }).pipe((0, _operators.share)())
    }; // object to hold our reactive methods

    var reactiveMethods = {};
    var cache = {
      find: {},
      get: {}
    };
    app.methods.forEach(function (method) {
      if (typeof service[method] === 'function') {
        reactiveMethods[method] = method === 'find' ? (0, _list.default)(options) : (0, _resource.default)(options, method);
      }
    });
    var mixin = {
      _cache: cache,
      created$: events.created,
      updated$: events.updated,
      patched$: events.patched,
      removed$: events.removed,
      rx: function rx() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        this._rx = options;
        return this;
      },
      watch: function watch() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var boundMethods = {};
        Object.keys(reactiveMethods).forEach(function (method) {
          var position = (0, _utils.getParamsPosition)(method);

          boundMethods[method] = function () {
            for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
              args[_key] = arguments[_key];
            }

            // inject `options` into `params.rx`
            args[position] = Object.assign(args[position] || {}, {
              rx: options
            });
            return reactiveMethods[method].apply(reactiveMethods, args);
          };
        });
        return boundMethods;
      }
    }; // get the extended service object

    var newService = service.mixin(mixin); // workaround for Firefox
    // FF defines Object.prototype.watch(), so uberproto doesn't recognize the mixin's .watch()
    // see https://github.com/feathersjs-ecosystem/feathers-reactive/issues/67

    if (Object.prototype.watch && Object.prototype.watch === newService.watch) {
      newService.watch = mixin.watch;
    } // bind the new service to all reactive methods


    for (var method in reactiveMethods) {
      reactiveMethods[method] = reactiveMethods[method].bind(newService);
    }
  };

  return function (app) {
    debug('Initializing feathers-reactive plugin');
    app.mixins.push(mixin);
  };
}

FeathersRx.strategy = _strategies.default;
FeathersRx.sift = _utils.sift;
module.exports = FeathersRx;