import {
    Autocomplete,
    Button,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Portal,
    Select,
    TextField,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { ModelPropType, PortalContainerPropType } from '../propTypes';
import { useApi } from '../../api/components/ApiProvider';
import { useModelTags } from '../hooks';

const getEmpty = () => ({ modelId: '', site: '' });

const schema = Yup.object().shape({
    sites: Yup.array()
        .of(
            Yup.object().shape({
                modelId: Yup.string().required('Required'),
                site: Yup.string().required('Required'),
            })
        )
        .required('Required'),
    tags: Yup.array().of(Yup.string()),
});

const ModelForm = ({ model, container, onDone }) => {
    const api = useApi();
    const tags = useModelTags();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            sites: model ? model.sites : [getEmpty()],
            tags: (model && model.tags) || [],
        },
        resolver: yupResolver(schema),
    });

    const {
        fields: siteFields,
        append: appendSite,
        remove: removeSite,
    } = useFieldArray({
        control,
        name: 'sites',
    });

    const onSubmit = (values) => {
        return (
            model
                ? api.service('models').patch(model._id, values)
                : api.service('models').create(values)
        ).then(() => {
            onDone();
        });
    };

    return (
        <form id="model-form" onSubmit={handleSubmit(onSubmit)}>
            {model ? <Divider sx={{ mt: 2, mb: 2 }} /> : null}

            <Grid container>
                {siteFields.map(({ id }, index) => (
                    <Grid item xs={12} key={id}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Controller
                                    name={`sites.${index}.site`}
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            variant="standard"
                                            margin="dense"
                                            fullWidth
                                            error={!!errors?.sites?.[index]?.site}
                                        >
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Page
                                            </InputLabel>
                                            <Select label="Page" {...field}>
                                                <MenuItem value="mfc">MFC</MenuItem>
                                                <MenuItem value="cb">CB</MenuItem>
                                                <MenuItem value="xh">XH</MenuItem>
                                            </Select>

                                            <FormHelperText>
                                                {errors?.sites?.[index]?.site?.message || ''}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            <Grid item xs>
                                <Controller
                                    name={`sites.${index}.modelId`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Name"
                                            fullWidth
                                            variant="standard"
                                            error={!!errors?.sites?.[index]?.modelId}
                                            helperText={
                                                errors?.sites?.[index]?.modelId?.message || ''
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item mt={2.5}>
                                <IconButton onClick={() => removeSite(index)}>
                                    <ClearIcon />
                                </IconButton>

                                <IconButton
                                    onClick={() => appendSite(getEmpty())}
                                    sx={{ opacity: index < siteFields.length - 1 ? 0 : 1 }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <Controller
                        onChange={([, data]) => data}
                        name="tags"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                multiple
                                freeSolo
                                options={tags}
                                onChange={(e, data) => {
                                    field.onChange(data);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            size="small"
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Tags"
                                        placeholder="add tag"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Portal container={container}>
                <Button form="model-form" type="submit">
                    Save
                </Button>
            </Portal>
        </form>
    );
};

ModelForm.propTypes = {
    model: ModelPropType,
    container: PortalContainerPropType,
    onDone: PropTypes.func.isRequired,
};

ModelForm.defaultProps = {
    model: null,
    container: null,
};

export default ModelForm;
