import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { TimeRange } from '@nivo/calendar';
import { memo, useCallback, useMemo, useRef } from 'react';
import { differenceInMinutes, format, max, parseISO, subDays } from 'date-fns';
import Tooltip from './Tooltip';

const STATISTICS_RECORDED_SINCE = new Date(2022, 1, 19);

const getStateValue = ({ online, awayish }) => {
    if (awayish > online) {
        return 1;
    }

    return online > 180 ? 3 : 2;
};

const getDuration = (activity) => {
    const from = parseISO(activity.from.checked);

    const till = activity.till ? parseISO(activity.till.checked) : new Date();

    return differenceInMinutes(till, from);
};

const getEarliest = (weeks, since) => {
    const dates = [
        subDays(new Date(), weeks * 7),
        since ? parseISO(since) : null,
        STATISTICS_RECORDED_SINCE,
    ].filter((date) => !!date);

    return subDays(max(dates), 1);
};

const calculateData = (statistics, from) => {
    if (!statistics) {
        return [];
    }

    const byDate = {};
    Object.entries(statistics).forEach(([, statistic]) => {
        statistic.activity.forEach((activity) => {
            const date = parseISO(activity.from.checked);
            const day = format(date, 'yyyy-MM-dd');

            if (from > day) {
                return;
            }

            if (!byDate[day]) {
                byDate[day] = {
                    online: 0,
                    awayish: 0,
                    activity: [],
                };
            }

            const minutes = getDuration(activity);

            if (activity.state === 'online') {
                byDate[day].online += minutes;
            } else {
                byDate[day].awayish += minutes;
            }

            byDate[day].activity.push(activity);
        });
    });

    return Object.entries(byDate).map(([day, state]) => ({
        ...state,
        value: getStateValue(state),
        day,
    }));
};

const OnlineStatistics = ({ statistics, since, direction, weeks }) => {
    const from = format(getEarliest(weeks, since), 'yyyy-MM-dd');
    const till = format(new Date(), 'yyyy-MM-dd');

    const data = useMemo(() => {
        return calculateData(statistics, from);
    }, [statistics, from]);

    const width = direction === 'vertical' ? 84 : (weeks + 1) * 12;
    const height = direction === 'horizontal' ? 84 : (weeks + 1) * 12;

    const container = useRef(null);

    const BoundTooltip = useCallback(
        (item) => <Tooltip item={item} container={container} />,
        [container]
    );

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <div ref={container} />

            <Box
                sx={{
                    width: '100%',
                    height,
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    textAlign: 'center',
                    mt: 1,
                    position: 'relative',
                }}
            >
                <TimeRange
                    width={width}
                    height={height}
                    data={data}
                    from={from}
                    to={till}
                    emptyColor="rgba(0, 0, 0, 0.17)"
                    colors={['#4b7bec', '#97e3d5', '#26de81']}
                    minValue={0}
                    maxValue={4}
                    dayBorderWidth={2}
                    dayBorderColor="#383838"
                    weekdayTicks={[]}
                    weekdayLegendOffset={0}
                    dayRadius={4}
                    isInteractive
                    tooltip={BoundTooltip}
                    daySpacing={0}
                    direction={direction}
                />
            </Box>
        </Box>
    );
};

OnlineStatistics.propTypes = {
    statistics: PropTypes.shape({}),
    since: PropTypes.string,
    direction: PropTypes.string,
    weeks: PropTypes.number,
};

OnlineStatistics.defaultProps = {
    statistics: null,
    since: null,
    direction: 'horizontal',
    weeks: 32,
};

export default memo(OnlineStatistics);
