"use strict";

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

module.exports = function () {
  return {
    never: function never(source$) {
      return source$;
    },
    always: function always(source$, options, args) {
      var params = args[0] || {};
      var query = Object.assign({}, params.query); // A function that returns if an item matches the query

      var matches = options.matcher(query);
      var events$ = (0, _rxjs.merge)(this.created$.pipe((0, _operators.filter)(matches)), this.removed$, this.updated$, this.patched$);
      return source$.pipe((0, _operators.concat)(events$.pipe((0, _operators.concatMapTo)(source$))));
    },
    smart: function smart(source$, options, args) {
      var params = args[0] || {};
      var query = Object.assign({}, params.query); // A function that returns if an item matches the query

      var matches = options.matcher(query); // A function that sorts a limits a result (paginated or not)

      var sortAndTrim = options.sorter(query, options);

      var onCreated = function onCreated(eventData) {
        return function (page) {
          var isPaginated = !!page[options.dataField];

          var process = function process(data) {
            return data.concat(eventData);
          };

          if (isPaginated) {
            return Object.assign({}, page, _defineProperty({
              total: page.total + 1
            }, options.dataField, process(page[options.dataField])));
          }

          return process(page);
        };
      };

      var onRemoved = function onRemoved(eventData) {
        return function (page) {
          var isPaginated = !!page[options.dataField];

          var process = function process(data) {
            return data.filter(function (current) {
              return eventData[options.idField] !== current[options.idField];
            });
          };

          if (isPaginated) {
            return Object.assign({}, page, _defineProperty({
              total: matches(eventData) ? page.total - 1 : page.total
            }, options.dataField, process(page[options.dataField])));
          }

          return process(page);
        };
      };

      var onUpdated = function onUpdated(eventData) {
        return function (page) {
          var isPaginated = !!page[options.dataField];
          var length = isPaginated ? page[options.dataField].length : page.length;

          var process = function process(data) {
            return data.filter(function (current) {
              return eventData[options.idField] !== current[options.idField];
            }).concat(eventData).filter(matches);
          };

          if (isPaginated) {
            var processed = process(page[options.dataField]);
            return Object.assign({}, page, _defineProperty({
              // Total can be either decreased or increased based
              // on if the update removed or added the item to the list
              total: page.total - (length - processed.length)
            }, options.dataField, processed));
          }

          return process(page);
        };
      };

      var events$ = (0, _rxjs.merge)(this.created$.pipe((0, _operators.filter)(matches), (0, _operators.map)(onCreated)), this.removed$.pipe((0, _operators.map)(onRemoved)), (0, _rxjs.merge)(this.updated$, this.patched$).pipe((0, _operators.map)(onUpdated)));
      return source$.pipe((0, _operators.concatMap)(function (data) {
        return (0, _rxjs.of)(data).pipe((0, _operators.concat)(events$.pipe((0, _operators.scan)(function (current, callback) {
          return sortAndTrim(callback(current));
        }, data))));
      }));
    }
  };
};