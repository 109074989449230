import PropTypes from 'prop-types';
import { Chip, Grid } from '@mui/material';

const Tags = ({ tags }) => {
    return (
        <Grid container spacing={1} sx={{ mt: 2 }}>
            {tags.map((tag) => (
                <Grid item key={tag}>
                    <Chip label={tag} size="small" />
                </Grid>
            ))}
        </Grid>
    );
};

Tags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
};

Tags.defaultProps = {
    tags: [],
};

export default Tags;
