import PropTypes from 'prop-types';

export const IdPropType = PropTypes.string;

export const PortalContainerPropType = PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.shape({}),
]);

export const SitePropType = PropTypes.shape({
    modelId: PropTypes.string.isRequired,
    preview: PropTypes.string,
    picture: PropTypes.string,
});

export const SitesPropType = PropTypes.arrayOf(SitePropType);

export const ModelPropType = PropTypes.shape({
    _id: IdPropType.isRequired,
    modelId: PropTypes.string.isRequired,
    preview: PropTypes.string,
    picture: PropTypes.string,
    state: PropTypes.string.isRequired,
    lastOnline: PropTypes.string,
    createdAt: PropTypes.string,
    watch: PropTypes.string,
    search: PropTypes.string,
    sites: SitesPropType.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    statistics: PropTypes.shape({}),
});
