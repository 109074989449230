import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useApi } from '../../api/components/ApiProvider';
import LoginForm from './LoginForm';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const Authenticate = ({ children }) => {
    const [initialized, setInitialized] = useState(false);
    const [user, setUser] = useState(null);
    const api = useApi();

    const auth = useMemo(
        () => ({
            user,
            login: ({ email, password }) => {
                return api
                    .authenticate({
                        strategy: 'local',
                        email,
                        password,
                    })
                    .then((response) => {
                        setUser(response.user);
                    })
                    .catch((err) => {
                        setUser(null);
                        throw err;
                    });
            },
            logout: () => {
                api.logout();
                setUser(null);
            },
        }),
        [user, api]
    );

    useEffect(() => {
        api.reAuthenticate()
            .then((resp) => {
                if (resp.user) {
                    setUser(resp.user);
                }
            })
            .catch(() => {
                setUser(null);
            })
            .finally(() => {
                setInitialized(true);
            });
    }, [api]);

    if (!initialized) {
        return (
            <Box sx={{ marginTop: '20%', width: '100%', textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!user) {
        return <LoginForm auth={auth} />;
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

Authenticate.propTypes = {
    children: PropTypes.node.isRequired,
};

Authenticate.defaultProps = {};

export default Authenticate;
