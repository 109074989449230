import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../api/components/ApiProvider';

export const useModels = () => {
    const api = useApi();

    const [models, setModels] = useState(null);

    useEffect(() => {
        const handle = api
            .service('models')
            .watch()
            .find({
                query: {
                    $sort: {
                        stateOrder: 1,
                        modelId: 1,
                    },
                },
            })
            .subscribe((result) => {
                setModels(result);
            });

        return () => {
            handle.unsubscribe();
        };
    }, [api]);

    return models;
};

export const useModel = (modelId) => {
    const api = useApi();
    const [model, setModel] = useState(null);

    useEffect(() => {
        if (modelId) {
            const handle = api
                .service('models')
                .watch()
                .get(modelId)
                .subscribe((result) => {
                    setModel(result);
                });

            return () => {
                handle.unsubscribe();
            };
        }

        setModel(null);
        return null;
    }, [api, modelId]);

    return model;
};

export const useModelTags = () => {
    const models = useModels();

    return useMemo(
        () =>
            (models?.data || []).reduce((carry, model) => {
                model.tags?.forEach((tag) => {
                    if (!carry.includes(tag)) {
                        carry.push(tag);
                    }
                });

                return carry;
            }, []),
        [models]
    );
};
