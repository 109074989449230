import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const DialogContext = createContext(null);

export const useDialog = () => useContext(DialogContext);

const DialogProvider = ({ children }) => {
    const dialog = useState(null);

    return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
};

DialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DialogProvider;
