import PropTypes from 'prop-types';
import { Box, Portal } from '@mui/material';
import Activity from './Activity';
import { ActivityPropType } from '../propTypes';
import { PortalContainerPropType } from '../../models/propTypes';

const Tooltip = ({ item, container }) => {
    const side = item.coordinates?.x < 200 ? 'left' : 'right';

    return (
        <Portal container={container?.current}>
            <Box
                sx={(theme) => ({
                    maxWidth: 134,
                    position: 'absolute',
                    left:
                        side === 'left'
                            ? item.coordinates.x + item.width + 8
                            : item.coordinates.x - item.width - 126,

                    borderRadius: 1,
                    padding: 1,
                    zIndex: 9999,
                    borderColor: theme.palette.grey[700],
                    borderWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor: theme.palette.background.paper,
                })}
            >
                {item.activity.map((entry) => (
                    <Activity activity={entry} key={entry.from.checked} />
                ))}
            </Box>
        </Portal>
    );
};

Tooltip.propTypes = {
    item: PropTypes.shape({
        coordinates: PropTypes.shape({ x: PropTypes.number.isRequired }).isRequired,
        width: PropTypes.number.isRequired,
        activity: PropTypes.arrayOf(ActivityPropType).isRequired,
    }).isRequired,
    container: PortalContainerPropType,
};

Tooltip.defaultProps = {
    container: null,
};

export default Tooltip;
