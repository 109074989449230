import configureApi from './modules/api/configureApi';
import ModelCards from './modules/models/components/ModelCards';
import ApiProvider from './modules/api/components/ApiProvider';
import Layout from './modules/layout/components/Layout';
import DialogProvider from './modules/dialog/components/DialogProvider';
import ModelDialog from './modules/models/components/ModelDialog';
import Authenticate from './modules/auth/components/Authenticate';
import Menu from './modules/menu/components/Menu';
import InfoDialog from './modules/info/components/InfoDialog';

const api = configureApi();

const App = () => {
    return (
        <ApiProvider api={api}>
            <DialogProvider>
                <Layout>
                    <Authenticate>
                        <Menu />
                        <ModelCards />
                        <ModelDialog />
                        <InfoDialog />
                    </Authenticate>
                </Layout>
            </DialogProvider>
        </ApiProvider>
    );
};

export default App;
