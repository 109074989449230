import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDialog } from '../../dialog/components/DialogProvider';
import { useAuth } from '../../auth/components/Authenticate';
import { MODEL_DIALOG } from '../../models/components/ModelDialog';
import { INFO_DIALOG } from '../../info/components/InfoDialog';

const openWindow = (url, target) => () => {
    window.open(url, target, 'noreferrer');
};

const Menu = () => {
    const [, setDialog] = useDialog();
    const { logout } = useAuth();

    return (
        <SpeedDial
            ariaLabel="Menu"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                icon={<AddAPhotoIcon />}
                tooltipTitle="Add"
                onClick={() => setDialog({ type: MODEL_DIALOG, modelId: null })}
            />
            <SpeedDialAction icon={<LogoutIcon />} tooltipTitle="Logout" onClick={logout} />
            <SpeedDialAction
                icon="XH"
                tooltipTitle="XHamsterLive"
                onClick={openWindow('https://xhamsterlive.com/', 'CB')}
            />
            <SpeedDialAction
                icon="CB"
                tooltipTitle="Chaturbate"
                onClick={openWindow('https://chaturbate.com/', 'CB')}
            />
            <SpeedDialAction
                icon="MFC"
                tooltipTitle="MyFreeCams"
                onClick={openWindow('https://myfreecams.com/', 'MFC')}
            />
            <SpeedDialAction
                icon={<InfoOutlinedIcon />}
                tooltipTitle="Info"
                onClick={() => setDialog({ type: INFO_DIALOG })}
            />
        </SpeedDial>
    );
};

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
