import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useState } from 'react';
import { IdPropType } from '../propTypes';
import { useApi } from '../../api/components/ApiProvider';

const DeleteButton = ({ modelId, onDone }) => {
    const [confirm, setConfirm] = useState(false);

    const api = useApi();

    const handleDelete = () => {
        return api
            .service('models')
            .remove(modelId)
            .then(() => {
                onDone();
            });
    };

    const handleConfirm = () => {
        setConfirm(true);
    };

    const handleAbort = () => {
        setConfirm(false);
    };

    return confirm ? (
        <>
            <Button onClick={handleDelete}>Confirm</Button>
            <Button onClick={handleAbort}>Abort</Button>
        </>
    ) : (
        <Button onClick={handleConfirm}>Delete</Button>
    );
};

DeleteButton.propTypes = {
    modelId: IdPropType.isRequired,
    onDone: PropTypes.func.isRequired,
};

DeleteButton.defaultProps = {};

export default DeleteButton;
