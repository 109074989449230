import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';

const StateBar = styled(Box)(({ theme, state }) => ({
    height: 4,
    width: '100%',
    backgroundColor: theme.palette.state[state],
}));

StateBar.propTypes = {
    state: PropTypes.string.isRequired,
};

StateBar.defaultProps = {};

export default StateBar;
