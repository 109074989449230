import PropTypes from 'prop-types';
import { differenceInSeconds, parseISO } from 'date-fns';
import StateBar from '../../layout/components/StateBar';

const getWidth = (lastUpdate, refreshTime) => {
    if (!lastUpdate || !refreshTime) {
        return 100;
    }

    const checked = parseISO(lastUpdate);
    const refreshed = parseISO(refreshTime);

    const timeToRefresh = differenceInSeconds(checked, refreshed);
    const timeToRefreshAll = differenceInSeconds(new Date(), refreshed);
    return (100 * timeToRefresh) / timeToRefreshAll;
};

const ModelState = ({ state, lastUpdate, refreshTime }) => {
    return (
        <>
            <StateBar
                state={state}
                sx={{
                    width: `${getWidth(lastUpdate, refreshTime)}%`,
                    position: 'absolute',
                    right: 0,
                }}
            />

            <StateBar state={state} sx={{ opacity: 0.71 }} />
        </>
    );
};

ModelState.propTypes = {
    state: PropTypes.string.isRequired,
    lastUpdate: PropTypes.string,
    refreshTime: PropTypes.string,
};

ModelState.defaultProps = {
    lastUpdate: null,
    refreshTime: null,
};

export default ModelState;
