import PropTypes from 'prop-types';

export const ActivityPropType = PropTypes.shape({
    state: PropTypes.string.isRequired,
    from: PropTypes.shape({
        lastOnline: PropTypes.string.isRequired,
        checked: PropTypes.string.isRequired,
    }).isRequired,
    confirmed: PropTypes.shape({
        lastOnline: PropTypes.string.isRequired,
        checked: PropTypes.string.isRequired,
    }),
    till: PropTypes.shape({
        lastOnline: PropTypes.string,
        checked: PropTypes.string.isRequired,
    }),
});
