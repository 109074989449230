import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import reactive from 'feathers-reactive/dist/feathers-reactive';

const configureApi = () => {
    // Establish a Socket.io connection
    const socket = io(process.env.REACT_APP_API_URL);

    socket.on('disconnect', () => {
        window.location.reload();
    });

    // Initialize our Feathers client application through Socket.io
    // with hooks and authentication.
    return feathers()
        .configure(feathers.socketio(socket))
        .configure(
            feathers.authentication({
                storage: window.localStorage,
            })
        )
        .configure(
            reactive({
                idField: '_id',
            })
        );
};

export default configureApi;
