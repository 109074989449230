import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ActivityPropType } from '../propTypes';

const Activity = ({ activity }) => {
    const fromChecked = parseISO(activity.from.checked);
    const tillChecked = activity.till ? parseISO(activity.till.checked) : null;

    return (
        <>
            <Typography style={{ fontSize: '0.6rem' }} color="text.disabled">
                {format(fromChecked, 'hh:mm aaaa')}
                {tillChecked ? <> - {format(tillChecked, 'hh:mm aaaa')}</> : null}
            </Typography>

            <Typography textAlign="left">{activity.state}</Typography>
        </>
    );
};

Activity.propTypes = {
    activity: ActivityPropType.isRequired,
};

Activity.defaultProps = {};

export default Activity;
