import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../dialog/components/DialogProvider';
import ModelForm from './ModelForm';
import { useModel } from '../hooks';
import ModelDetails from './ModelDetails';
import DeleteButton from './DeleteButton';
import { useApi } from '../../api/components/ApiProvider';
import Tags from '../../layout/components/Tags';

export const MODEL_DIALOG = 'ModelDialog';

const ModelDialog = () => {
    const api = useApi();
    const [dialog, setDialog] = useDialog();
    const [container, setContainer] = useState(null);
    const handleRef = useCallback((instance) => setContainer(instance), [setContainer]);
    const model = useModel(dialog?.modelId);
    const [edit, setEdit] = useState(false);

    const handleClose = () => {
        setDialog(null);
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const handleRefresh = () => {
        return api.service('models').patch(model._id, { refresh: true });
    };

    useEffect(() => {
        if (dialog) {
            if (!dialog.modelId) {
                setEdit(true);
            }
        } else {
            setEdit(false);
        }
    }, [dialog, setEdit]);

    return (
        <Dialog open={dialog?.type === MODEL_DIALOG} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ paddingBottom: 0 }}>
                {dialog?.modelId === null ? 'New Model' : model?.modelId || 'Edit Model'}
            </DialogTitle>

            <DialogContent>
                {model ? <ModelDetails model={model} /> : null}

                {edit ? (
                    <ModelForm model={model} container={container} onDone={handleClose} />
                ) : null}

                {!edit && model?.tags ? <Tags tags={model?.tags} /> : null}
            </DialogContent>

            <DialogActions>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item>
                        {!edit && model ? <Button onClick={handleRefresh}>Refresh</Button> : null}
                    </Grid>
                    <Grid item>
                        {!edit ? <Button onClick={handleEdit}>Edit</Button> : null}
                        {edit && dialog?.modelId ? (
                            <DeleteButton modelId={dialog.modelId} onDone={handleClose} />
                        ) : null}
                        <Button onClick={handleClose}>{edit ? 'Cancel' : 'Close'}</Button>
                        <div style={{ display: 'inline-block' }} ref={handleRef} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

ModelDialog.propTypes = {};

ModelDialog.defaultProps = {};

export default ModelDialog;
