import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { sortBy, orderBy } from 'lodash';
import SortIcon from '@mui/icons-material/Sort';
import ModelCard from './ModelCard';
import { useModels } from '../hooks';
import { isOnlineIsh } from '../utils';
import { getRefreshTime } from '../../info/components/RefreshTime';

const emptyMessages = [
    'No one online...',
    'Everyone left...',
    'Everyone has better things to do...',
    'All gone...',
    'Hello... anyone there?',
    '*tumbleweeds rolling*',
    'No one there...',
    'Such void...',
    'Just wait a minute...',
    'Soon...',
    'Where is everybody?',
    'Helloooo?',
];

const getModelSlug = (model) => model.modelId.toLowerCase().replace(/[^a-z]/g, '');

const ORDER_BY_NAME = 'Name';
const ORDER_BY_LAST_ONLINE = 'Recent';
const ORDER_BY_CHECKED = 'Checked';

const getOrdered = (models, order) => {
    switch (order) {
        case ORDER_BY_LAST_ONLINE:
            return orderBy(models, ['lastOnline', getModelSlug], ['desc', 'asc']);
        case ORDER_BY_CHECKED:
            return orderBy(models, ['lastUpdate', getModelSlug], ['desc', 'asc']);
        default:
            return orderBy(models, ['stateOrder', getModelSlug], ['asc', 'asc']);
    }
};

const ModelCards = () => {
    const models = useModels();
    const [order, setOrder] = useState(ORDER_BY_NAME);

    const refreshTime = useMemo(() => {
        return getRefreshTime(models)?.lastUpdate;
    }, [models]);

    useEffect(() => {
        const count = models?.data.filter((model) => model.state === 'online').length || 0;
        document.title = `${count} online | Notifier`;
    }, [models]);

    const emptyMessage = useMemo(() => {
        return emptyMessages[Math.floor(Math.random() * emptyMessages.length)];
    }, []);

    const { online, offline } = useMemo(() => {
        const byState = (models?.data || []).reduce(
            (carry, model) => {
                if (isOnlineIsh(model.state)) {
                    carry.online.push(model);
                } else {
                    carry.offline.push(model);
                }
                return carry;
            },
            { online: [], offline: [] }
        );

        return {
            online: sortBy(byState.online, ['stateOrder', getModelSlug]),
            offline: getOrdered(byState.offline, order),
        };
    }, [models, order]);

    const toggleOrderBy = () => {
        setOrder((stale) => {
            switch (stale) {
                case ORDER_BY_NAME:
                    return ORDER_BY_LAST_ONLINE;
                case ORDER_BY_LAST_ONLINE:
                    return ORDER_BY_CHECKED;
                case ORDER_BY_CHECKED:
                    return ORDER_BY_NAME;
                default:
                    return ORDER_BY_NAME;
            }
        });
    };

    return (
        <>
            <Typography variant="h2" sx={{ marginBottom: 1, opacity: 0.15 }}>
                Online{' '}
                {online.length > 0 ? (
                    <Typography component="span" variant="inherit" sx={{ opacity: 0.4 }}>
                        {online.length}
                    </Typography>
                ) : null}
            </Typography>

            <Grid container spacing={2}>
                {online.map((model) => (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={model._id}>
                        <ModelCard modelId={model._id} refreshTime={refreshTime} />
                    </Grid>
                ))}

                {online.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography sx={{ textAlign: 'center', opacity: 0.3 }}>
                            {emptyMessage}
                        </Typography>
                    </Grid>
                ) : null}
            </Grid>

            <Grid container sx={{ marginTop: 4, marginBottom: 1, opacity: 0.15 }}>
                <Grid item xs>
                    <Typography variant="h2">
                        Offline{' '}
                        <Typography component="span" variant="inherit" sx={{ opacity: 0.4 }}>
                            {offline.length}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        onClick={toggleOrderBy}
                        sx={{ cursor: 'pointer', userSelect: 'none' }}
                    >
                        <Typography variant="h2">{order}</Typography>
                        <SortIcon sx={{ opacity: 0.4 }} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {offline.map((model) => (
                    <Grid item xs={12} sm={4} md={3} lg={3} key={model._id}>
                        <ModelCard modelId={model._id} refreshTime={refreshTime} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

ModelCards.propTypes = {};

export default ModelCards;
