import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { ApiPropType } from '../propTypes';

const ApiContext = createContext(null);

export const useApi = () => useContext(ApiContext);

const ApiProvider = ({ api, children }) => {
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
    api: ApiPropType.isRequired,
    children: PropTypes.node.isRequired,
};

export default ApiProvider;
