import { Box, Grid, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import { ModelPropType } from '../propTypes';
import { formatLastOnline, getPreviewUrl, SITE_LABELS } from '../utils';
import WatchLink from './WatchLink';
import OnlineStatistics from '../../statistics/components/OnlineStatistics';

const ModelDetails = ({ model }) => {
    const preview = getPreviewUrl(model);

    return (
        <Grid container>
            <Grid item xs sm={6}>
                <List dense>
                    <ListItem>
                        <ListItemText primary={model.state} secondary="state" />
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={formatLastOnline(model.state, model.lastOnline)}
                            secondary="last online"
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={formatDistanceToNow(parseISO(model.lastUpdate), {
                                addSuffix: true,
                            })}
                            secondary="checked"
                        />
                    </ListItem>

                    {model.createdAt ? (
                        <ListItem>
                            <ListItemText
                                primary={format(parseISO(model.createdAt), 'dd.MM.yyyy')}
                                secondary="added"
                            />
                        </ListItem>
                    ) : null}
                </List>
            </Grid>

            <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
                <OnlineStatistics
                    statistics={model.statistics}
                    since={model.createdAt}
                    direction="vertical"
                    weeks={16}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                {preview ? (
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <WatchLink model={model}>
                            <img alt={model.modelId} src={preview} style={{ width: '100%' }} />
                        </WatchLink>
                    </Box>
                ) : null}
                <Grid container>
                    {model.sites.map((site) => (
                        <Grid item key={site.site}>
                            <Link
                                href={site.search || 'https://mycamgirl.net/'}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="caption" component="span">
                                    <SearchIcon
                                        fontSize="small"
                                        sx={{ marginRight: 1, position: 'relative', top: 6 }}
                                    />
                                    {SITE_LABELS[site.site]}
                                </Typography>
                            </Link>

                            <Link
                                href={`https://www.google.com/search?q=${site.modelId}+${site.site}+twitter`}
                                target="_blank"
                                rel="noreferrer"
                                sx={{ ml: 1 }}
                            >
                                <Typography variant="caption" component="span">
                                    Google
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <OnlineStatistics statistics={model.statistics} since={model.createdAt} />
            </Grid>
        </Grid>
    );
};

ModelDetails.propTypes = {
    model: ModelPropType.isRequired,
};

ModelDetails.defaultProps = {};

export default ModelDetails;
