import { CardMedia, Grid, Skeleton } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ModelPropType } from '../propTypes';
import { getPreviewUrl } from '../utils';

const GRAYSCALE = {
    online: 0,
    'in private': 60,
    'in group': 60,
    away: 60,
    'offline*': 60,
    offline: 100,
    unknown: 100,
    invalid: 100,
};

const HEIGHT = 200;

const ModelPicture = ({ model }) => {
    const previewUrl = getPreviewUrl(model);

    if (previewUrl) {
        return (
            <CardMedia
                sx={{
                    filter: `grayscale(${GRAYSCALE[model.state]}%)`,
                    objectPosition: 'top',
                }}
                component="img"
                height={HEIGHT + (model.state === 'online' ? 1 : 0)}
                image={previewUrl}
            />
        );
    }

    if (model.state === 'unknown') {
        return (
            <Skeleton
                animation={model.state === 'unknown' ? 'pulse' : false}
                variant="rectangular"
                height={HEIGHT}
            />
        );
    }

    return (
        <Grid container sx={{ height: HEIGHT }} justifyContent="center" alignItems="center">
            <Grid item>
                <PriorityHighIcon sx={{ marginTop: -10 }} fontSize="large" color="disabled" />
            </Grid>
        </Grid>
    );
};

ModelPicture.propTypes = {
    model: ModelPropType.isRequired,
};

ModelPicture.defaultProps = {};

export default ModelPicture;
