"use strict";

var _utils = require("./utils");

var _cache = require("./cache");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

module.exports = function (settings, method) {
  return function () {
    var _this = this;

    var position = (0, _utils.getParamsPosition)(method);
    var params = arguments[position] || {};
    var cachedObservable = method === 'get' ? (0, _cache.getCachedObservable)(this._cache, 'get',
    /* id */
    arguments[0]) : undefined; // check if cached Observable exists

    if (cachedObservable) {
      return cachedObservable;
    } // create new Observable resource


    var options = (0, _utils.getOptions)(settings, this._rx, params.rx);
    var source = (0, _utils.getSource)(this[method].bind(this), arguments);
    var stream = source.pipe((0, _operators.concatMap)(function (data) {
      // Filter only data with the same id
      var filterFn = function filterFn(current) {
        return current[options.idField] === data[options.idField];
      }; // `removed` events get special treatment


      var filteredRemoves = _this.removed$.pipe((0, _operators.filter)(filterFn)); // `created`, `updated` and `patched`


      var filteredEvents = (0, _rxjs.merge)(_this.created$, _this.updated$, _this.patched$).pipe((0, _operators.filter)(filterFn));
      var combinedEvents = (0, _rxjs.merge)( // Map to a callback that merges old and new data
      filteredEvents, // filtered `removed` events always mapped to `null`
      filteredRemoves.pipe((0, _operators.mapTo)(null)));
      return (0, _rxjs.of)(data).pipe((0, _operators.concat)(combinedEvents));
    }));
    var pipeStream = (0, _utils.getPipeStream)(stream, options); // if the method is `get` cache the result, otherwise just return the stream

    return method === 'get' ? (0, _cache.cacheObservable)(this._cache, 'get',
    /* id */
    arguments[0], pipeStream) : pipeStream;
  };
};