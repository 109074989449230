"use strict";

var _jsonStableStringify = _interopRequireDefault(require("json-stable-stringify"));

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _debug2 = _interopRequireDefault(require("debug"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var debug = (0, _debug2.default)('feathers-reactive');

function cacheObservable(cache, method, key, observable) {
  var hash = _hash(key);

  var cachedObservable = observable.pipe((0, _operators.finalize)(function () {
    // clean cache on unsubscription (of all observers)
    debug('removing cache item: ', hash);
    delete cache[method][hash];
  }), _oldStyleShareReplay(1));
  cache[method][hash] = cachedObservable;
  return cache[method][hash];
}

function getCachedObservable(cache, method, key) {
  var hash = _hash(key);

  return cache[method][hash];
}

function _hash(key) {
  return (0, _jsonStableStringify.default)(key);
} // eslint:disable

/* We relied on faulty behavior fixed in https://github.com/ReactiveX/rxjs/commit/accbcd0c5f9fd5976be3f491d454c4a61f699c4b.
   This is the old shareReplay that tears down when refCount hits 0 */


function _oldStyleShareReplay(bufferSize, windowTime, scheduler) {
  var subject;
  var connectable = (0, _operators.multicast)(function shareReplaySubjectFactory() {
    if (this._isComplete) {
      return subject;
    } else {
      return subject = new _rxjs.ReplaySubject(bufferSize, windowTime, scheduler);
    }
  });
  return function (source) {
    return (0, _operators.refCount)()(connectable(source));
  };
}

Object.assign(exports, {
  cacheObservable: cacheObservable,
  getCachedObservable: getCachedObservable
});