import { formatDistanceToNow, parseISO } from 'date-fns';

const ONLINE_ISH_STATES = ['new', 'online', 'in private', 'in group', 'away'];

export const SITE_LABELS = {
    cb: 'Chaturbate',
    mfc: 'MyFreeCams',
    xh: 'XHamsterLive',
};

export const isOnlineIsh = (state) => {
    return ONLINE_ISH_STATES.includes(state);
};

export const formatLastOnline = (state, date) => {
    if (!date) {
        return 'never';
    }
    if (state === 'online') {
        return 'now';
    }

    return formatDistanceToNow(parseISO(date), { addSuffix: true });
};

export const getPreviewUrl = (model) => {
    const preview = model.preview || model.picture;
    return preview && `${process.env.REACT_APP_MEDIA_URL}${preview}?now=${model.lastUpdate}`;
};
