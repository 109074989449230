"use strict";

var _sift = _interopRequireDefault(require("sift"));

var _utils = require("@feathersjs/commons/lib/utils");

var _adapterCommons = require("@feathersjs/adapter-commons");

var _rxjs = require("rxjs");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function getSource(originalMethod, args) {
  return (0, _rxjs.defer)(function () {
    return originalMethod.apply(void 0, _toConsumableArray(args));
  });
}

function makeSorter(query, options) {
  // The sort function (if $sort is set)
  var sorter = query.$sort ? (0, _adapterCommons.sorter)(query.$sort) : (0, _adapterCommons.sorter)(_defineProperty({}, options.idField, 1));
  return function (result) {
    var isPaginated = !!result[options.dataField];
    var data = isPaginated ? result[options.dataField] : result;

    if (sorter) {
      data = data.sort(sorter);
    }

    var limit = typeof result.limit === 'number' ? result.limit : parseInt(query.$limit, 10);

    if (limit && !isNaN(limit) && limit !== -1) {
      data = data.slice(0, limit);
    }

    if (isPaginated) {
      result[options.dataField] = data;
    } else {
      result = data;
    }

    return result;
  };
}

function getOptions(base) {
  for (var _len = arguments.length, others = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    others[_key - 1] = arguments[_key];
  }

  var options = Object.assign.apply(Object, [{}, base].concat(others));

  if (typeof options.listStrategy === 'string') {
    options.listStrategy = options.listStrategies[options.listStrategy];
  }

  return options;
}

function getPipeStream(stream, options) {
  if (!options.pipe) {
    return stream;
  } else if (Array.isArray(options.pipe)) {
    return stream.pipe.apply(stream, _toConsumableArray(options.pipe));
  } else {
    return stream.pipe(options.pipe);
  }
}

function getParamsPosition(method) {
  // The position of the params parameters for a service method so that we can extend them
  // default is 1
  var paramsPositions = {
    find: 0,
    update: 2,
    patch: 2
  };
  return method in paramsPositions ? paramsPositions[method] : 1;
}

function siftMatcher(originalQuery) {
  var keysToOmit = Object.keys(originalQuery).filter(function (key) {
    return key.charCodeAt(0) === 36;
  });

  var query = _utils._.omit.apply(_utils._, [originalQuery].concat(_toConsumableArray(keysToOmit)));

  return (0, _sift.default)(query);
}

Object.assign(exports, {
  sift: _sift.default,
  getSource: getSource,
  makeSorter: makeSorter,
  getOptions: getOptions,
  getParamsPosition: getParamsPosition,
  siftMatcher: siftMatcher,
  getPipeStream: getPipeStream
});