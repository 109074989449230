import PropTypes from 'prop-types';
import { ModelPropType } from '../propTypes';

const WatchLink = ({ model, children }) => {
    return (
        <a href={model.watch} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            {children}
        </a>
    );
};

WatchLink.propTypes = {
    model: ModelPropType.isRequired,
    children: PropTypes.node.isRequired,
};

WatchLink.defaultProps = {};

export default WatchLink;
