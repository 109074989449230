import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@mui/material';
import { IdPropType } from '../propTypes';
import { useModel } from '../hooks';
import ModelPicture from './ModelPicture';
import LastOnline from './LastOnline';
import WatchLink from './WatchLink';
import ModelState from './ModelState';
import { isOnlineIsh } from '../utils';
import { useDialog } from '../../dialog/components/DialogProvider';
import { MODEL_DIALOG } from './ModelDialog';

const ModelCard = ({ modelId, refreshTime }) => {
    const model = useModel(modelId);
    const [, setDialog] = useDialog();

    const openDialog = () => {
        setDialog({ type: MODEL_DIALOG, modelId });
    };

    return (
        model && (
            <Card
                sx={{ width: '100%', position: 'relative' }}
                variant={isOnlineIsh(model.state) ? 'elevation' : 'outlined'}
            >
                <WatchLink model={model}>
                    <ModelPicture model={model} />
                </WatchLink>
                <CardContent sx={{ position: 'relative', cursor: 'pointer' }} onClick={openDialog}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                        {model.modelId}
                    </Typography>
                    <LastOnline state={model.state} date={model.lastOnline} />
                </CardContent>
                <ModelState
                    state={model.state}
                    lastUpdate={model.lastUpdate}
                    refreshTime={refreshTime}
                />
            </Card>
        )
    );
};

ModelCard.propTypes = {
    modelId: IdPropType.isRequired,
    refreshTime: PropTypes.string,
};

ModelCard.defaultProps = {
    refreshTime: null,
};

export default ModelCard;
