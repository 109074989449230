import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Prefiller = ({ onFill }) => {
    useEffect(() => {
        const downHandler = (event) => {
            if (event.ctrlKey && event.code === 'KeyA') {
                onFill();
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, [onFill]);

    return null;
};

const FormPrefiller = (props) => {
    return process.env.NODE_ENV !== 'production' ? <Prefiller {...props} /> : null;
};

FormPrefiller.propTypes = {
    onFill: PropTypes.func.isRequired,
};

FormPrefiller.defaultProps = {};

export default FormPrefiller;
