import { useMemo } from 'react';
import { minBy } from 'lodash';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useModels } from '../../models/hooks';

export const getRefreshTime = (models) => {
    return minBy(models?.data || [], 'lastUpdate');
};

export const useRefreshTime = () => {
    const models = useModels();

    return useMemo(() => {
        return getRefreshTime(models)?.lastUpdate;
    }, [models]);
};

const RefreshTime = () => {
    const oldestRefresh = useRefreshTime();

    return oldestRefresh
        ? formatDistanceToNow(parseISO(oldestRefresh), { addSuffix: true })
        : 'never';
};

RefreshTime.propTypes = {};

RefreshTime.defaultProps = {};

export default RefreshTime;
