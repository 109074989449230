import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, Button, Container, Fade, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { AuthPropType } from '../propTypes';
import FormPrefiller from './Prefiller';

const schema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

const LoginForm = ({ auth }) => {
    const [loginError, setLoginError] = useState(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (values) => {
        return auth.login(values).catch((err) => {
            setLoginError(err.message);
        });
    };

    return (
        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
            <FormPrefiller
                onFill={() => {
                    setValue('email', 'admin@test.email');
                    setValue('password', 'complexpassword1');
                }}
            />
            <Container maxWidth="xs">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Fade in={!!loginError}>
                            <Alert severity="error" variant="outlined">
                                {loginError}
                            </Alert>
                        </Fade>
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Email"
                                    fullWidth
                                    variant="standard"
                                    error={!!errors?.email}
                                    helperText={errors?.email?.message || ''}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    margin="dense"
                                    label="Password"
                                    fullWidth
                                    variant="standard"
                                    type="password"
                                    error={!!errors?.password}
                                    helperText={errors?.password?.message || ''}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit">Login</Button>
                    </Grid>
                </Grid>
            </Container>
        </form>
    );
};

LoginForm.propTypes = {
    auth: AuthPropType.isRequired,
};

LoginForm.defaultProps = {};

export default LoginForm;
