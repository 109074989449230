import PropTypes from 'prop-types';
import { differenceInDays, parseISO } from 'date-fns';
import { Typography } from '@mui/material';
import { formatLastOnline } from '../utils';

const getOpacity = (date) => {
    if (!date) {
        return 50;
    }

    const lastOnline = parseISO(date);
    const daysOffline = differenceInDays(new Date(), lastOnline);
    return 100 - Math.min(1, daysOffline / 360) ** 2 * 50; // max at 180 days
};

const LastOnline = ({ state, date }) => {
    return (
        <Typography
            variant="caption"
            sx={{ opacity: `${getOpacity(date)}%` }}
            color="text.disabled"
        >
            {formatLastOnline(state, date)}
        </Typography>
    );
};

LastOnline.propTypes = {
    state: PropTypes.string.isRequired,
    date: PropTypes.string,
};

LastOnline.defaultProps = {
    date: null,
};

export default LastOnline;
