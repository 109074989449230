import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        mode: 'dark',

        state: {
            online: '#26de81',
            'in private': '#a55eea',
            'in group': '#a55eea',
            away: '#4b7bec',
            'offline*': '#8a93a1',
            offline: '#121212',
            unknown: '#fd9644',
            invalid: '#eb3b5a',
            new: '#086e6e',
            default: '#fd9644',
        },
    },

    typography: {
        h2: {
            fontSize: '1.4rem',
        },

        h5: {
            fontSize: '1.1rem',
        },
    },
});

export default theme;
