import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { useDialog } from '../../dialog/components/DialogProvider';
import RefreshTime from './RefreshTime';
import config from '../../../../package.json';

export const INFO_DIALOG = 'InfoDialog';

const InfoDialog = () => {
    const [dialog, setDialog] = useDialog();
    const handleClose = () => {
        setDialog(null);
    };

    return (
        <Dialog open={dialog?.type === INFO_DIALOG} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ paddingBottom: 0 }}>Notifier v{config.version}</DialogTitle>

            <DialogContent>
                <List dense>
                    <ListItem>
                        <ListItemText primary={<RefreshTime />} secondary="refresh time" />
                    </ListItem>
                </List>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={1} justifyContent="end">
                    <Grid item>
                        <Button onClick={handleClose}>Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

InfoDialog.propTypes = {};

InfoDialog.defaultProps = {};

export default InfoDialog;
