"use strict";

var _utils = require("./utils");

var _cache = require("./cache");

module.exports = function (settings) {
  return function (params) {
    var cachedObservable = (0, _cache.getCachedObservable)(this._cache, 'find', params); // return cached Observable if it exists

    if (cachedObservable) {
      return cachedObservable;
    }

    var options = (0, _utils.getOptions)(settings, this._rx, params.rx);
    var source = (0, _utils.getSource)(this.find.bind(this), arguments);
    var stream = options.listStrategy.call(this, source, options, arguments);
    var pipeStream = (0, _utils.getPipeStream)(stream, options); // set cache and return cached observable

    return (0, _cache.cacheObservable)(this._cache, 'find', params, pipeStream);
  };
};