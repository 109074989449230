import PropTypes from 'prop-types';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../../../styles/theme';

const Layout = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Container>
                <Box mt={2} mb={2}>
                    {children}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
